import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useState } from 'react';

import { Typography, Box, Snackbar, Alert } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'next-i18next';

import { CouponBox, CouponButton, CouponContainer, CouponImageBox } from './style';

import { useDialog } from 'src/components/atoms/dialog';
import { useAppData, useToggleState } from 'src/helpers/use';
import { getAssetUrl, numberWithCommas } from 'src/helpers/utils';

const CouponModal = dynamic(import('src/components/atoms/dialog/coupon'));

type Props = {
  isShowCoupon: boolean;
};

const Coupon = ({ isShowCoupon }: Props) => {
  const { t } = useTranslation('common');
  const { appData } = useAppData();
  const couponDialog = useDialog();
  const [open, setOpen, setClose] = useToggleState();
  const [alert, setAlert] = useState({ message: '', isError: false });

  const matches = useMediaQuery('(max-width: 1024px) and (min-width: 768px)');

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={3000}
        onClose={setClose}
      >
        <Alert onClose={setClose} variant="filled" severity={alert.isError ? 'error' : 'success'}>
          {t(alert.message)}
        </Alert>
      </Snackbar>
      {isShowCoupon && (
        <CouponContainer>
          {couponDialog.render(<CouponModal dialog={couponDialog} setOpenAlert={setOpen} setAlert={setAlert} />)}
          <CouponBox>
            <Box sx={{ display: 'flex' }}>
              <CouponImageBox>
                <Image src={getAssetUrl('icons/coupon.svg')} alt="coupon" layout="fill" />
              </CouponImageBox>
              <Box sx={{ ml: 3, color: 'white', display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="h4"
                  className="title-coupon"
                  sx={{ fontWeight: 'bold', fontSize: { xs: '18px', md: '24px' } }}
                >
                  {t('coupon.get_price_coupon', {
                    price: numberWithCommas(appData?.couponNewUser.price!),
                  })}
                  <Typography
                    className="desc"
                    variant={matches ? 'h4' : 'h6'}
                    component={matches ? 'span' : 'div'}
                    sx={{ lineHeight: 1, ml: matches ? '8px' : 0, fontWeight: matches ? 'bold' : 'normal' }}
                  >
                    {t('coupon.for_first_course')}
                  </Typography>
                </Typography>
              </Box>
            </Box>

            <CouponButton variant="outlined" color="primary" onClick={couponDialog.open}>
              {t('click_here')}
            </CouponButton>
          </CouponBox>
        </CouponContainer>
      )}
    </>
  );
};

export default Coupon;
