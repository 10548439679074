import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CouponContainer = styled(Box)(
  ({ theme }) => `
  position: fixed;
  ${theme.breakpoints.down('lg')} {
    position: sticky;
    top: 62px;
    right: 0;
    width: 100vw;
  }
  bottom: 20px;
  right: 20px;
  z-index: 99;
  width: 400px; 
  height: 70px;
`,
);

export const CouponBox = styled(Box)(
  ({ theme }) => `
    ${theme.breakpoints.down('lg')} {
      border-radius: 0;
    }
    ${theme.breakpoints.down('md')} {
      .desc {
        font-size: 16px;
      }
    }
    @media (max-width: 320px) {
      padding: 12px 20px;
      .title-coupon {
        font-size: 16px;
      }
    }
    @media (max-width: 280px) {
      .desc {
        font-size: 12px;
      }
    }
    background-color: ${theme.palette.secondary.main};
    padding: 12px 27px;
    border: 1px solid ${theme.palette.secondary.main};
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`,
);

export const CouponImageBox = styled(Box)(
  ({ theme }) => `
    position: relative;
    width: 39px;
    height: 39px;
    ${theme.breakpoints.up('lg')} {
      width: 50px;
      height: 50px;
    }
`,
);

export const CouponButton = styled(Button)(
  ({ theme }) => `
  background: white;
  border-radius: 51px;
  height: 40px;
  width: 120px;
  ${theme.breakpoints.down('md')} {
    height: 35px;
    width: 92px;
  }
  @media (max-width: 320px) {
    width: 80px;
  }
  :hover {
    background-color: #F5F8F7;
    border: 1px solid ${theme.palette.secondary.main};
    }
  }
`,
);
