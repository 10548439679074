import { forwardRef, useState, useCallback, ReactElement, Ref } from 'react';
import { useDispatch } from 'react-redux';

import { DialogProps } from '@mui/material/Dialog';
import { PaperProps } from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { noop } from 'lodash';

import { DialogStyled } from './style';

import appActions from 'src/redux/app/actions';

const { set_is_dialog_open } = appActions;

// eslint-disable-next-line max-len
const TransitionDown = forwardRef(
  (props: TransitionProps & { children: ReactElement<any, any> }, ref: Ref<unknown>) => (
    <Slide direction="down" ref={ref} {...props} />
  ),
);

const TransitionUp = forwardRef((props: TransitionProps & { children: ReactElement<any, any> }, ref: Ref<unknown>) => (
  <Slide direction="up" ref={ref} {...props} />
));

// eslint-disable-next-line max-len
const TransitionLeft = forwardRef(
  (props: TransitionProps & { children: ReactElement<any, any> }, ref: Ref<unknown>) => (
    <Slide direction="left" ref={ref} {...props} />
  ),
);

// eslint-disable-next-line max-len
const TransitionRight = forwardRef(
  (props: TransitionProps & { children: ReactElement<any, any> }, ref: Ref<unknown>) => (
    <Slide direction="right" ref={ref} {...props} />
  ),
);

export type DialogType = {
  opened: boolean;
  open: () => void;
  close: () => void;
  render: (children: ReactElement, dialogProps?: Omit<DialogProps, 'open'>, disabledBackDropClick?: boolean) => any;
};

export enum TransitionDirection {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
}

export type UseDialogProps = {
  keepMounted?: boolean;
  maxWidth?: number | string;
  disabledBackDropClick?: boolean;
  fullWidth?: boolean;
  fullScreen?: boolean;
  transitionDirection?: TransitionDirection;
  paperStyle?: Partial<PaperProps['sx']>;
  dialogProps?: Omit<DialogProps, 'open'>;
  onClose?: typeof noop;
};

export const useDialog = ({
  keepMounted = true,
  maxWidth = 500,
  disabledBackDropClick = false,
  fullWidth = false,
  fullScreen = false,
  transitionDirection = TransitionDirection.DOWN,
  paperStyle = {},
  dialogProps = {},
}: UseDialogProps = {}) => {
  const [opened, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const open = useCallback(() => {
    setOpen(true);
    dispatch(set_is_dialog_open(true));
  }, [dispatch]);

  const close = useCallback(() => {
    setOpen(false);
    dispatch(set_is_dialog_open(false));
  }, [dispatch]);

  const closeWithoutBackDrop = useCallback((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  }, []);

  const TransitionComponent = {
    [TransitionDirection.UP]: TransitionUp,
    [TransitionDirection.DOWN]: TransitionDown,
    [TransitionDirection.LEFT]: TransitionLeft,
    [TransitionDirection.RIGHT]: TransitionRight,
  };

  const render = (children: ReactElement) => (
    <DialogStyled
      {...dialogProps}
      open={opened}
      TransitionComponent={TransitionComponent[transitionDirection]}
      keepMounted={keepMounted}
      onClose={disabledBackDropClick ? closeWithoutBackDrop : close}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          borderRadius: fullScreen ? 0 : '10px',
          overflow: dialogProps?.scroll ? 'auto' : 'hidden',
          margin: fullScreen ? '0px' : '14px',
          padding: '16px',
          maxWidth,
          ...(paperStyle || {}),
        },
      }}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
    >
      {children}
    </DialogStyled>
  );

  return {
    opened,
    open,
    close,
    render,
  };
};
