import { Box, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ImageBox = styled(Box)(
  ({ theme }) => `
    position: relative;
`,
);

export const FormBox = styled(Box)`
  .form {
    width: 300px;
  }
  @media (max-width: 320px) {
    padding: 0 1rem;
    .form {
      width: 240px;
    }
    .button-submit-coupon {
      margin-top: 0.25rem;
      margin-bottom: 1rem;
    }
  }
  @media (max-width: 280px) {
    .form {
      width: 200px;
    }
  }
`;

export const DiscountBox = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: 50%;
    top: 52%;
    left: 70%;
    transform: translate(-50%, -50%);
    color: ${theme.palette.primary.main};
    .title {
      font-size: 24px;
      font-weight: bold;
      line-height: 20px;
    }
    .price {
      font-size: 64px;
      font-weight: bold;
      line-height: 64px;
    }
    .desc {
      font-size: 18px;
      line-height: 20px;
    }
    .remark {
      font-size: 14px;
      line-height: 22px;
      color: ${theme.palette.text.secondary};
      margin-top: -4px;
    }
    ${theme.breakpoints.down('lg')} {
      left: 72%;
      .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 24px;
      }
      .price {
        font-size: 64px;
        font-weight: bold;
        line-height: 64px;
      }
      .desc {
        font-size: 18px;
        line-height: 22px;
      }
    }
    ${theme.breakpoints.down('md')} {
      .title {
        font-size: 14px;
        line-height: 16px;
      }
      .price {
        font-size: 36px;
        font-weight: bold;
        line-height: 36px;
      }
      .desc {
        font-size: 12px;
        line-height: 26px;
      }
      .remark {
        font-size: 10px;
        line-height: 14px;
        margin-top: -8px;
      }
    }
    @media (max-width: 320px) {
      left: 68%;
    }
`,
);

export const DialogStyled = styled(Dialog)(
  ({ theme, fullScreen }) => `
    align-items: flex-start;
    margin-top: ${fullScreen ? 0 : '4rem'};
    ${[theme.breakpoints.up('sm')]} {
      margin-top: ${fullScreen ? 0 : '6rem'};
    }
    ${[theme.breakpoints.up('lg')]} {
      align-items: center;
      margin-top: 0rem;
    }
  `,
);
